import { FormHeadingStyles } from 'styles'
import React from 'react'

const Heading = () => {
  return (
    <FormHeadingStyles>
      Let&apos;s Create Something Beautiful!
    </FormHeadingStyles>
  )
}

export default Heading
